export default defineNuxtRouteMiddleware((to, from) => {
   if (to.name === from.name) return;

   if (PUBLIC_ROUTE_NAMES.includes(to.name?.toString())) return;

   const { loadingUserInfo, getUserInfo } = useUserInfoStore();

   if (!loadingUserInfo) {
      getUserInfo();
   }
});
